:root {
    --blue: #001489;
    --yellow: #FFD100;
    --grey: #222731;
    --yellow-gradient-start: #FFD100;
    --yellow-gradient-end: #FFF0AA;
    --light-grey: #D9D9D6;
    --light-grey-2: #888B8D;
    --grey-tab: #F0F0F0;
    --light-blue:#62adf6;

}

@font-face {
    font-family: 'Brandon';
    src: url('assets/fonts/BrandonText-Regular.woff2') format('woff2'),
    url('assets/fonts/BrandonText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Brandon Bold';
    src: url('assets/fonts/BrandonText-Bold.woff2') format('woff2'),
    url('assets/fonts/BrandonText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

body {
    margin: 0;
    font-family: 'Brandon', 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: greyscale;
    -webkit-touch-callout: none; /* iOS Safari */
    /*-webkit-user-select: none; !* Safari *!
    -khtml-user-select: none; !* Konqueror HTML *!
    -moz-user-select: none; !* Old versions of Firefox *!
    -ms-user-select: none; !* Internet Explorer/Edge *!
    user-select: none; !* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox *!*/
}

h2 {
    font-family: 'Brandon Bold'
}
p:not(.text-smaller), ul {
    font-size: 14px!important;
}
.fontHelvetica {
    font-family: 'Brandon';
}
a.bkg-primary:hover, .btn:hover {
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.5) !important;
    background-color: var(--blue);
    color: #ffffff;
}
.readerContainer a.bkg-primary:hover, .btn:hover {
    box-shadow: none!important;
}
.App {
    max-height: 80vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-dark-blue{
    color: var(--grey);
}
.text-blue {
    color: var(--blue)
}

.container {
    max-width: 1100px !important;
}

.bkg-primary {
    background-color: var(--blue);
}

.bkg-secondary {
    background-color: var(--yellow);
}

.bkg-grey {
    background-color: var(--grey);
}

.bkg-ligth-grey {
    background-color: var(--light-grey-2);
}

.bkg-ligth-grey-2 {
    background-color: var(--grey-tab);
}

.bkg-gradient {
    background-image: linear-gradient(180deg, var(--yellow-gradient-start) 0%, var(--yellow-gradient-start) 60%, var(--yellow-gradient-end) 94%);
}
.btnNav {
    min-width: 100px;
    color: white;
}
.btnNav.disabled {
    opacity: 0.5!important;
}
.btnNav:hover {

  /* border: 1px solid var(--blue);
    color:var(--blue);
    background-color: transparent;*/
}

.nav-fill .nav-item button {
    color: #000!important;
}
.nav-fill .nav-item button.active {
    color: var(--blue)!important;
}
.bottomBtn .btn.btn-primary {
    background-color: #64aaf0 !important;
    border: 2px groove var(--light-grey) !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom:0!important;
    font-size: 14px;
}
.bottomBtn .btn.btn-secondary {
    background-color: #d4dcdc !important;
    color: black;
    border: 2px groove var(--light-grey) !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom:0!important;
}
.btn.btn-secondary {
    background-color: #d4dcdc !important;
    color: black;
    border: 2px groove var(--light-grey-2) !important;
    font-size: 14px;
}
.btn.upperBtn {
    background-color: #62adf6;
    border-top: 0!important;
    border-right: 0!important;
}
.text-yellow {
    color: var(--yellow) !important;
}

.text-orange {
    color: var(--blue) !important;
}

.text-light-grey {
    color: var(--light-grey);
}

p, ul {
    font-size: 14.5px;
    line-height: 1.5em
}

.border-groove {
    border: 2px groove var(--light-grey) !important;
    border-radius: 8px;
}

.border-gradient {
    border-top: 5px solid transparent; /* Impostiamo il bordo superiore trasparente */
    border-image: linear-gradient(to right, var(--yellow-gradient-start) 0%, var(--yellow-gradient-end) 100%); /* Applichiamo il gradiente al bordo */
    border-image-slice: 1; /* Specifica come viene tagliata l'immagine del bordo (vedi documentazione per ulteriori informazioni) */
}
.roundBg {

    background-image: url("assets/img/Home/round-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
}

a {
    text-decoration: none;
    color: black;
}

.text-smaller {
    font-size: 10px;
}

/*posizionamenti per testo su immagini con didascalie*/
.image-container {
    position: relative;
    width: 100%;
}

.image-container .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    /*transform: translate(-50%, -50%);*/
}

.image-container .text {
    white-space: break-spaces;
    font-weight: bold;
    /*text-align: start;*/
}

.image-container .secondaryText {
    max-width: 70%;

}

/*fine posizionamento */
@media screen and ( max-width: 900px) {
    .container {
        max-width: 90vw !important;
    }

    .container p {
        font-size: 14px !important;
    }
    .innerMainContent {
        height: auto;
        overflow-y:auto;
        overflow-x: hidden;
    }
}

@media screen and ( min-width: 1100px) {
    .container {
        max-width: 1100px !important;
    }

    .innerMainContent {
        height: 700px !important;
            overflow-y:auto;
        overflow-x: hidden;
    }


}

.custom-checkbox input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkbox label {
    position: relative;
    display: inline-block;
    padding-left: 25px;
    margin-right: 15px;
    cursor: pointer;
}

.custom-checkbox label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    border: 1px solid #adb5bd;
    background-color: #fff;
}

.custom-checkbox input[type="radio"]:checked + label:before {
    content: "\2713";
    text-align: center;
    line-height: 16px;
    color: #007bff;
    border-color: #007bff;
}

.arrowIcon.disabled + span.text-black {
    color: #dedede !important;
}

/*Nav Pills e Tab styling*/
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #000;
    background-color: var(--grey-tab);
}

.nav-pills .nav-link {
    border-bottom: 1px solid;
    color: #000;
    border-radius: 0;
}

.importantInfo .tab-content > .active, .specifications .tab-content > .active {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 500px !important;
}
.fixedHeightP {
    min-height: 50px;
}